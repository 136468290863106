//import { slugify } from './utils/string';

/**
 * Returns an array of data containing all the settings to go in headerLinks
 * Takes an array of what links should be in the dropdown and in what order
 * @param {array} array - List of numbers representing links and their ordering
 * @param {boolean} boolean - Boolean to hide offers tab or not in main header, defaults to showing
 */
export const getHeaderLinks = (order, hideOffers) => {
  const linkOptions = {
    1: {
      label: 'Ferries',
      href: '/ferry-deals',
    },
    2: {
      label: 'Hotels',
      href: '/hotels',
    },
    3: {
      label: 'UK Attractions',
      href: '/attractions',
    },
    4: {
      label: 'Inspiration',
      href: '/inspiration',
    },
    5: {
      label: 'Themed Breaks',
      href: '/themed-breaks',
    },
    6: {
      label: 'Caravan Sales',
      href: '/holiday-home-sales',
    },
    7: {
      label: 'Breakfree Blog',
      href: 'https://www.breakfreeholidays.co.uk/blog/',
    },
  };

  const linksToReturn = [];
  order.forEach((numb) => {
    linksToReturn.push(linkOptions[numb]);
  });

  return [
    !hideOffers && {
      label: 'Offers',
      href: '/offers',
    },
    {
      type: 'channels',
    },
    linksToReturn.length && {
      label: 'More',
      items: linksToReturn,
    },
  ];
};

/**
 * Returns an object that contains all of the API endpoint URLs used by the site
 * @param {object} config - The site config object
 * @param {string} source - The source to use for the API (api or mocks). Default: api.
 */
export const getApi = (config, source = 'api') => {
  switch (source) {
    case 'mocks':
      return {
        init: '/mocks/init.json',
        channels: '/mocks/data/channels.json',
        regions: '/mocks/data/regions.json',
        channelregions: '/mocks/data/channelRegions.json',
        parkDetails: '/mocks/park/parkdetails.json',
        parkResults: '/mocks/park/parkresults.json',
        parkReviews: '/mocks/park/parkreviews.json',
        parks: '/mocks/data/parks.json',
        login: '/mocks/user/login.json', // Wont currently work with mocks as this is a POST request
        logout: '/mocks/user/logout.json',
        operators: '/mocks/data/operators.json',
        filters: '/mocks/data/filters.json',
        search: '/mocks/search/search.json',
        searchResults: '/mocks/search/searchresults.json',
        booking: '/mocks/booking/booking.json',
        bookingGetDeposit: '/mocks/booking/getdeposit.json',
        bookingGetExtras: '/mocks/booking/getextras.json',
        bookingSetExtras: '/mocks/booking/setextras.json',
        bookingSetPartyDetails: '/mocks/booking/setpartydetails.json',
        bookingPrePayment: '/mocks/booking/prepayment.json',
        bookingPostPayment: '/mocks/booking/postpayment.json',
        userSetDetails: '/mocks/user/setuserdetails.json', // Wont currently work with mocks as this is a POST request
        passwordReset: '/mocks/user/requestpasswordreset.json', // Wont currently work with mocks as this is a POST request
      };
    default:
      return {
        init: `${config['api.url']}/api/initial`,
        regions: '/api/channelregions',
        parkDetails: '/api/parkdetails',
        parkResults: '/api/parkresults',
        parkReviews: '/api/tripadvisor',
        parks: '/api/parks',
        // eslint-disable-next-line no-undef
        login: process.env.NODE_ENV === 'development' ? '/mocks/user/login.json' : '/api/login',
        // eslint-disable-next-line no-undef
        logout: process.env.NODE_ENV === 'development' ? '/mocks/user/logout.json' : '/api/logout',
        operators: '/api/operators',
        search: '/api/search',
        searchResults: '/api/searchresults',
        booking: '/api/book',
        bookingGetDeposit: '/api/getdeposit',
        bookingGetExtras: '/api/getextras',
        bookingSetExtras: '/api/setextras',
        bookingSetPartyDetails: '/api/setpartydetails',
        bookingPrePayment:
          // eslint-disable-next-line no-undef
          process.env.NODE_ENV === 'development' ? '/mocks/booking/prepayment.json' : '/api/prepayment',
        bookingPostPayment: '/api/postpayment',
        bookingSetDiscount: '/api/setdiscount',
        userGetBookings: '/api/mybookings',
        userSetDetails: '/api/setuserdetails',
        passwordReset: '/api/resetpassword',
        newsletterSignup: '/api/newsletter',
        contact: '/api/contact',
        cottageDates: '/api/getcottagedates',
        cms: `${config['cms.url']}`, // This should point to the CMS used for content (currently headless wordpress)
        nav: '/nav.json',
        cachedAvail: '/json-files/avail-summary/CHUKCRVN_availSummary.json',
        unitTypesExtraData: (operatorName, parkName) =>
          `https://content.breakfreeholidays.co.uk/json/accom-types/${operatorName}/${parkName}.json`,
        unitTypesExtraDataPID: (parkID) =>
          `https://content.breakfreeholidays.co.uk/json/accom-types/new/${parkID}.json`,
      };
  }
};

/**
 * Returns an object that contains all of the default CMS content route data
 * @param {object} config - The site config object
 */
export const getContentRoutes = () => {
  return [
    { path: '/insurance', url: 'age/83' },
    { path: '/ferry-deals', url: 'age/212' },
    { path: '/attractions', url: 'age/656' },
    { path: '/weekend-breaks', url: 'age/1876' },
    { path: '/holiday-home-sales', url: 'age/1386' },
    { path: '/uk-spa-breaks', url: 'age/2290' },
    { path: '/themed-breaks', url: 'age/1265' },
  ];
};

/**
 * Returns an object that contains all of the footer links
 * @param {object} config - The site config object
 */
export const getFooterLinks = (config) => ({
  findAHoliday1: {
    title: 'footer.nav.holidayType',
    links: [
      { id: 'ukHolidayParks', title: 'UK Holiday Parks', path: '/channel/ukcaravan' },
      { id: 'campingTouring', title: 'Camping & Touring', path: '/channel/uktouring' },
      { id: 'hotels', title: 'Hotels', path: '/hotels' },
      { id: 'lodges', title: 'Lodges', path: '/channel/uklodge' },
      { id: 'boats', title: 'Boating Holidays', path: '/region/boats' },
      { id: 'europeanHolidays', title: 'European Holidays', path: '/channel/eurocaravan' },
      { id: 'ferryDeals', title: 'Ferry Deals', path: '/ferry-deals' },
      { id: 'cheapercaravan', title: 'Cheap Caravan Holidays', path: '/cheap-caravan-holidays-uk-europe' },
      {
        id: 'offers',
        title: 'Holiday Offers',
        path: '/offers',
      },
    ],
  },
  findAHoliday2: {
    title: 'footer.nav.findAHoliday',
    links: [
      { id: 'byBrand', title: 'Holidays by Brand', path: '/a/3559/brands' },
      { id: 'holidaysSuffolk', title: 'Holidays in Suffolk', path: '/holidays/ukcaravan/4/suffolk' },
    ],
  },
  holidays: {
    title: 'footer.nav.companyName',
    links: [
      { id: 'help', title: 'Contact Us/FAQs', path: '/help' },
      {
        id: 'blog',
        title: 'Holiday Blog',
        path: `${config['global.siteUrl']}/blog`,
        external: true,
      },
      { id: 'about', title: 'About us', path: '/about-us' },
      {
        id: 'becomeAffiliate',
        title: 'Become an Affiliate',
        path: 'https://ui.awin.com/merchant-profile/2594',
        external: true,
      },
    ],
  },
  information: {
    title: 'footer.nav.information',
    links: [
      { id: 'cancellation-protection', title: 'Cancellation Protection', path: '/a/4324/cancellation-protection' },
      { id: 'terms', title: 'Terms & Conditions', path: '/terms' },
      { id: 'privacy', title: 'Privacy & Cookie Policy', path: '/privacy-policy' },
      { id: 'charter', title: 'Customer Charter & Price Promise', path: '/customer-charter' },
    ],
  },
});

// NOTES

// Choose if you want to use 3D Secure for payments or not. Please note that this has liability implications.
// See https://developer.paypal.com/docs/business/checkout/add-capabilities/3d-secure for more info.

// The avoid CORS errors, the local dev environment will proxy requests to the API server.
// To change API server that local dev environment proxies to, change the 'proxy' field in package.json.
// CRA only supports one proxy server, so we use it for the API server. Requests to the CMS are not proxied.

const GOOGLE_MAPS_KEY = 'AIzaSyB7PsauX1x8tFPwX-6zd5BSVvFHBtuRclc';

export const defaultConfig = {
  'awin.enable': false,
  'awin.trackingId': '2594',
  'cms.url': undefined,
  'footer.showReviews': true,
  'footer.showTrust': true,
  'footer.newsletter.bgColour': 'dark-blue',
  'footer.trust.logoHeight': 32,
  'forms.enableNewsletterSignup': true,
  // eslint-disable-next-line no-undef
  googleMapsApiKey: GOOGLE_MAPS_KEY,
  'google.gtm': undefined,
  'global.brandName': undefined,
  'global.feefoReviews': true,
  'global.spinnerIcon': 'spinner-circle',
  'global.spinnerTheme': 'primary',
  'global.siteUrl': undefined,
  'global.email': undefined,
  'global.textHighlight': 'alt-primary',
  'global.textBlackHighlight': 'black',
  'header.logo.height': 40,
  'header.static': false,
  'header.style': 'dark',
  'header.megaMenu': 'megamenu-default.min.json',
  'header.showOffersLink': true,
  'parks.showFeefoRating': true,
  'parks.showReviews': true,
  'payment.enable3DSecure': true,
  'payment.paypal': true,
  'payment.paypalCard': true,
  'payment.payit': false,
  siteCode: 'BF',
  'searchBox.buttonColour': 'secondary',
  'searchBox.allowFiveDays': false,
  'socialMedia.telephone': undefined,
  'socialMedia.facebook': undefined,
  'socialMedia.instagram': undefined,
  'socialMedia.twitter': undefined,
  'socialMedia.youtube': undefined,
  'urls.privacyPolicy': '/privacy-policy',
  'urls.termsAndConditions': '/terms',
  'user.optin.emailOpt': true,
  'user.optin.smsOpt': true,
  'user.optin.postOpt': false,
  'user.optin.thirdOpt': true,
};
