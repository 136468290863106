// Selectors
export const searchResultsChannelIdSelector = (state) => state.search.results.params.channelId;
export const searchResultsRegionIdSelector = (state) => state.search.results.params.regionId;
export const searchResultsArrivalDateSelector = (state) => state.search.results.params.arrivalDate;
export const searchResultsDurationSelector = (state) => state.search.results.params.duration;

export const searchResultsSelector = (state) => state.search.results.results;
export const searchResultsCountSelector = (state) => state.search.results.count;

export const searchResultsParamsSelector = (state) => state.search.results.params;
export const searchResultsPendingParamsSelector = (state) => state.search.results.pendingParams;
export const searchResultsSearchIdSelector = (state) => state.search.results.searchId;
