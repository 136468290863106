import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { config } from '../../../config';
import t from '../../../text';
import { usePageWidth } from '../../hooks/usePageWidth';
import { minPricesMonthsSelector } from '../../redux/data/cachedAvail/selectors';
import { selectChannel, selectMonth } from '../../redux/search/form/actions';
import {
  searchFormArrivalDateSelector,
  searchFormMonthSelector,
  searchFormParamsSelector,
} from '../../redux/search/form/selectors';
import { useNewsletter } from '../Footer/NewsletterContext';
import { ChannelSelect } from './ChannelSelect';
import { DateDurationSelect } from './DateDurationSelect';
import { GuestsSelect } from './GuestsSelect';
import { RegionSelect } from './RegionSelect';
import { SearchBoxMobile } from './SearchBoxMobile';
import { useHandleSearch } from './useHandleSearch';
import { useLocation } from 'react-router-dom';

const COL_CLASS_NAME = 'px-lg-1 px-xl-2';

export const SearchBox = ({ isHeroPage = false, includeEmail = false, isHomePage = false }) => {
  const dispatch = useDispatch();
  const { width } = usePageWidth();
  const className = isHomePage ? '' : 'bg-dark pt-3 pb-3 pb-lg-2';

  const month = useSelector(searchFormMonthSelector);
  const arrivalDate = useSelector(searchFormArrivalDateSelector);

  const _minPricesMonths = useSelector(minPricesMonthsSelector);

  const [showSearchModal, setShowSearchModal] = useState(false);
  const toggleSearchModal = () => setShowSearchModal((show) => !show);

  useEffect(() => {
    if (window.location.pathname === '/') {
      selectChannel(dispatch, 'ukcaravan');
    }

    if (!month && !arrivalDate) {
      selectMonth(dispatch, _minPricesMonths[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On a mobile the search box opens in a modal, now that we have resized to medium we want to close it
  useEffect(() => {
    if (width < 768) return;
    if (showSearchModal) setShowSearchModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  const { search } = useLocation();
  const isResultsPage = search.length > 0;

  return (
    <Container fluid className={`${className} ${isResultsPage && 'search-box-sticky'}`}>
      <SearchBoxMobile toggleSearchModal={toggleSearchModal} />

      <Container className="search-box" id="search-modal">
        <BaseForm
          isHeroPage={isHeroPage}
          setShowSearchModal={setShowSearchModal}
          includeEmail={includeEmail}
          className="d-none d-md-block"
        />
      </Container>

      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        centered
        dialogClassName={showSearchModal ? 'is-open' : ''}
        id="search-modal"
      >
        <i className="icon-close h2 close-search-modal" onClick={toggleSearchModal} />
        <Modal.Body className="pt-0 pb-4">
          <BaseForm isHeroPage={isHeroPage} setShowSearchModal={setShowSearchModal} includeEmail={includeEmail} />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

SearchBox.propTypes = {
  isHeroPage: PropTypes.bool,
  includeEmail: PropTypes.bool,
  isHomePage: PropTypes.bool,
};

const BaseForm = ({ isHeroPage, setShowSearchModal, includeEmail, className }) => {
  const params = useSelector(searchFormParamsSelector);

  const { handleSearch } = useHandleSearch();

  const [email, setEmail] = useState('');

  const { hasSignedUpForDeals } = useNewsletter();

  const showSignUpUI = includeEmail && !hasSignedUpForDeals;

  const formSubmit = (event) => {
    setShowSearchModal(false);
    handleSearch(event, email);
  };

  return (
    <Form onSubmit={formSubmit} className={className}>
      <Row xs={1}>
        <Col lg={10}>
          <Row>
            <Col md={6} lg className={COL_CLASS_NAME}>
              <ChannelSelect />
            </Col>
            <Col md={6} lg className={COL_CLASS_NAME}>
              <RegionSelect />
            </Col>
            <Col md={6} lg className={COL_CLASS_NAME}>
              <DateDurationSelect />
            </Col>
            <Col md={6} lg className={COL_CLASS_NAME}>
              <GuestsSelect />
            </Col>
            {showSignUpUI && (
              <Col md={6} lg className={COL_CLASS_NAME}>
                <Form.Group controlId="EmailAddress">
                  <Form.Label className="text-white">{t('search.dealSignup')}</Form.Label>
                  <Form.Control
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email address"
                    className="mt-md-0 toggle-popup"
                  />
                  <Form.Control.Feedback type="invalid">{t('footer.newsletter.invalidEmail')}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
          </Row>
        </Col>

        <Col lg={2} className={`pr-lg-1 px-xl-2 ${isHeroPage && 'mt-2'}`}>
          {!isHeroPage && <Form.Label>&nbsp;</Form.Label>}
          <Button
            block
            variant={config['searchBox.buttonColour']}
            type="submit"
            disabled={
              !params.channelId || !params.regionId || !params.arrivalDate || !params.duration || params.adults === 0
            }
          >
            {t('search.search')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

BaseForm.propTypes = {
  isHeroPage: PropTypes.bool,
  setShowSearchModal: PropTypes.func,
  includeEmail: PropTypes.bool,
  className: PropTypes.string,
};
