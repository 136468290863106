import { useDispatch, useSelector } from 'react-redux';
import { useFetchSearchResults } from '../../hooks/useFetchSearchResults';
import { useUpdateRoute } from '../../hooks/useUpdateRoute';
import { useNewsletter } from '../Footer/NewsletterContext';
import { searchFormPetFriendlySelector, searchFormRegionIdSelector } from '../../redux/search/form/selectors';
import { urls } from '../../../config';
import { resetBFilters, resetNFilters, setState } from '../../redux/appState/actions';
import { resetSliderValues } from '../../redux/data/rangeSlider/actions';

export const useHandleSearch = () => {
  const dispatch = useDispatch();
  const selectedRegionId = useSelector(searchFormRegionIdSelector);
  const petFriendly = useSelector(searchFormPetFriendlySelector);

  const { hasSignedUpForDeals, setHasSignedUpForDeals } = useNewsletter();

  const { updateRoute } = useUpdateRoute();
  const { fetchSearchResults } = useFetchSearchResults();

  const handleSearch = async (event, email) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    resetSliderValues(dispatch);
    resetNFilters(dispatch);
    resetBFilters(dispatch);

    if (!hasSignedUpForDeals && email && form.checkValidity() === true) {
      try {
        const response = await fetch(`${urls.newsletterSignup}?email=${encodeURIComponent(email)}`);
        const data = await response.json();
        const isSuccessful = data.success === 1;

        if (isSuccessful) {
          setHasSignedUpForDeals(true);
        }
      } catch (error) {
        // Error
      }
    }

    // Ensure that state update and other side effects are processed before redirecting
    updateRoute();

    if (selectedRegionId !== 'all-regions') {
      fetchSearchResults(); // Ensure search results are fetched before redirect
    }

    setState(dispatch, 'searchResultsPetFriendlyFilters', petFriendly ? ['y'] : ['n']);
  };

  return { handleSearch };
};
