import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import {
  searchFormArrivalDateSelector,
  searchFormDurationSelector,
  searchFormPartySizeSelector,
  searchFormRegionNameSelector,
} from '../../redux/search/form/selectors';
import { getDayAndMonthNameFromDate, getDayNameFromDate } from '../../utils/date';
import { shouldItPluralize } from '../../utils/utils';

export const SearchBoxMobile = ({ toggleSearchModal }) => {
  const currentRegionName = useSelector(searchFormRegionNameSelector);
  const arrivalDate = useSelector(searchFormArrivalDateSelector);

  const dateFormatted = getDayAndMonthNameFromDate(new Date(arrivalDate), true);
  const dayFormatted = getDayNameFromDate(new Date(arrivalDate), true);
  const dateString = `${dayFormatted} ${dateFormatted}`;
  const duration = useSelector(searchFormDurationSelector);
  const partySize = useSelector(searchFormPartySizeSelector);

  const textLine1 = currentRegionName || 'Enter destination';

  const textLine2 = useMemo(() => {
    const text = [];
    if (dateString) text.push(dateString);
    if (duration) text.push(`${duration} nights`);
    if (partySize) {
      text.push(`${partySize} ${shouldItPluralize('guest', partySize)}`);
    }
    return text.join(' • ');
  }, [dateString, duration, partySize]);

  return (
    <Button
      className="d-md-none w-100 d-flex align-items-center justify-content-between px-1 py-2"
      variant="primary"
      onClick={toggleSearchModal}
    >
      <div className="text-left ml-3">
        <p className="mb-0" style={{ fontSize: '16px', lineHeight: '1.25rem' }}>
          {textLine1}
        </p>
        <p className="mb-0" style={{ fontSize: '12px' }}>
          {textLine2}
        </p>
      </div>
      <FaSearch className="mr-3" />
    </Button>
  );
};
