import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';

export const InputCheckbox = ({ onChange, checked, className, ariaLabel, label, id = 'not-set' }) => {
  const handleChange = onChange ? () => onChange(!checked) : undefined;

  return (
    <InputGroup className={className}>
      <InputGroup.Prepend className="cursor-pointer" onClick={handleChange}>
        <InputGroup.Checkbox
          id={id}
          className="cursor-pointer"
          checked={checked}
          onChange={handleChange}
          aria-label={ariaLabel || label}
        />
      </InputGroup.Prepend>
      <label htmlFor={id} className="form-control h-auto" dangerouslySetInnerHTML={{ __html: label }} />
    </InputGroup>
  );
};

InputCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InputCheckbox;
