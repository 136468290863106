import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { config, images } from '../../../config';
import { useIsMobile } from '../../hooks/useIsMobile';
import { channelsSelector } from '../../redux/data/channels/selectors';
import Logo from '../Logo';
import { HeaderNavDropdown } from './HeaderNavDropdown';
import HeaderNavLink from './HeaderNavLink';
import HeaderNavLoginButtons from './HeaderNavLoginButtons';
import { useShowOriginalBackground } from './useShowOriginalBackground';

const SORT_ORDER = ['ukcaravan', 'eurocaravan', 'uklodge', 'hotels', 'uktouring', 'boats', 'ukcottages'];

export const HeaderNav = ({ loggedIn, onLogout, onShowLogin, menuData }) => {
  const history = useHistory();
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const location = useLocation();
  const { isMobile } = useIsMobile();
  const { showOriginalBackground } = useShowOriginalBackground();

  // channel data and sort
  const _channels = useSelector(channelsSelector);

  const menuItems = useMemo(() => {
    const baseItems = [..._channels.map((channel) => channel.code), 'hotels'];
    const filteredItems = baseItems.filter((item) => item !== 'uktouring');
    return filteredItems.sort((a, b) => SORT_ORDER.indexOf(a) - SORT_ORDER.indexOf(b));
  }, [_channels]);

  // Collapse nav whenever we change route
  useEffect(() => {
    setMobileExpanded(false);
  }, [location]);

  // Because only one of the mobile dropdowns can be open we need to track it here
  const [channelOpen, setChannelOpen] = useState(null);

  const showDropdownOnDesktop = (newChannel) => {
    if (isMobile) return;
    const isCurrentChannel = newChannel === channelOpen;
    setChannelOpen(isCurrentChannel ? null : newChannel);
  };

  const handleDropdownClick = (newChannel, isChannel) => {
    if (!isMobile) {
      if (newChannel === 'hotels') {
        history.push({ pathname: '/hotels' });
      } else if (isChannel) {
        history.push({ pathname: `/region/${newChannel}` });
      }
      setChannelOpen(null);
      return;
    }
    const isCurrentChannel = newChannel === channelOpen;
    setChannelOpen(isCurrentChannel ? null : newChannel);
  };

  const hideDropdownOnDesktop = () => {
    if (isMobile) return;
    setChannelOpen(null);
  };

  const handleCloseNavbars = () => {
    setMobileExpanded(false);
    setChannelOpen(null);
  };

  return (
    <Navbar
      expand="lg"
      collapseOnSelect
      bg={showOriginalBackground ? config['header.style'] : 'light'}
      id="main-navigation"
      variant={config['header.style']}
      className="p-3 p-lg-0" // keep padding on mobile, take it off on lg so we can replace with margin for dropdowns
      sticky="top"
      data-style={showOriginalBackground ? 'dark' : 'light'}
      expanded={mobileExpanded}
    >
      <Container>
        <NavLink to="/" exact>
          <Navbar.Brand className="mr-md-3">
            <Logo
              variant={showOriginalBackground ? config['header.style'] : 'light'}
              logoDark={images.headerLogoDark}
              logoLight={images.headerLogoLight}
              height={config['header.logo.height']}
            />
          </Navbar.Brand>
        </NavLink>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          id="nav-toggle"
          onClick={() => setMobileExpanded((m) => !m)}
        >
          <span />
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav" className="scrollable-nav-container">
          <Nav className="mr-auto h-100 scrollable-nav">
            {menuItems.map((channel, i) => {
              const dataSet = menuData[channel];
              const { title, columns, isChannel, image } = dataSet;
              return (
                <HeaderNavDropdown
                  key={i}
                  channelOpen={channelOpen}
                  showDropdownOnDesktop={() => showDropdownOnDesktop(channel)}
                  handleDropdownClick={() => handleDropdownClick(channel, isChannel)}
                  hideDropdownOnDesktop={() => hideDropdownOnDesktop(channel)}
                  handleCloseNavbars={handleCloseNavbars}
                  channel={channel}
                  title={title}
                  columns={columns}
                  isChannel={isChannel}
                  image={image}
                />
              );
            })}
            {config['header.showOffersLink'] && (
              <HeaderNavLink label="Offers" href="/offers" onClick={() => setMobileExpanded(false)} />
            )}
          </Nav>
          <HeaderNavLoginButtons {...{ loggedIn, onLogout, onShowLogin }} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const linkShape = PropTypes.shape({
  text: PropTypes.string,
  url: PropTypes.string,
});

const columnItemShape = PropTypes.shape({
  title: PropTypes.string,
  links: PropTypes.arrayOf(linkShape),
});

const menuDataItemShape = PropTypes.shape({
  columns: PropTypes.arrayOf(PropTypes.arrayOf(columnItemShape)),
  image: PropTypes.string,
  isChannel: PropTypes.bool,
  title: PropTypes.string,
});

HeaderNav.propTypes = {
  loggedIn: PropTypes.bool,
  onLogout: PropTypes.func,
  onShowLogin: PropTypes.func,
  menuData: PropTypes.objectOf(menuDataItemShape),
};
