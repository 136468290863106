import countries from '../base/Data/Countries.json';

export const BOOKING_STEPS = {
  EXTRAS: 'extras',
  DETAILS: 'details',
  PAYMENT: 'payment',
  PAYMENT_ACTION: 'payment-action',
  CONFIRMATION: 'confirmation',
};

export const HOLIDAY_TYPES = {
  EUROPEAN_HOLIDAY_PARKS: 'eurocaravan',
  BOATING_HOLIDAYS: 'boats',
  UK_LODGE_BREAKS: 'uklodge',
  UK_CAMPING_AND_TOURING_PARKS: 'uktouring',
  UK_COTTAGES: 'ukcottages',
  UK_HOLIDAY_PARKS: 'ukcaravan',
};

// Holiday type in here won't reset the date on duration change
export const CHANNELS_WHICH_DONT_RESET_DATE = [
  HOLIDAY_TYPES.BOATING_HOLIDAYS,
  HOLIDAY_TYPES.UK_CAMPING_AND_TOURING_PARKS,
  HOLIDAY_TYPES.UK_COTTAGES,
  HOLIDAY_TYPES.EUROPEAN_HOLIDAY_PARKS,
];

// Holiday types in here will filter available dates by holiday duration
export const CHANNELS_WITH_CUSTOM_FILTERING = [HOLIDAY_TYPES.UK_LODGE_BREAKS];

export const INTERNAL_LOCATIONS = ['/', 'https://www.breakfreeholidays', 'www.breakfreeholidays'];

export const BREAKPOINTS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const MAX_VALUE_ON_PRICE_RANGE = 1000;
export const MIN_VALUE_ON_PRICE_RANGE = 0;

export const FAKE_USER_DATA = {
  dobYr: '1993',
  addrLn3: '',
  tel: '07951787223',
  postOpt: 'Y',
  county: 'Essex',
  smsOpt: 'Y',
  country: 'CNNORTHE',
  title: 'Mr',
  dobDay: '20',
  dobMnth: '05',
  email: 'wakeling1000@hotmail.com',
  redeyeUrl:
    '//reporting.breakfreeholidays.co.uk/cgi-bin/rr/blank.gif?nourl=log-on&email=wakeling101%40hotmail.com&custref=562310',
  emailOpt: 'N',
  lastName: 'Wakeling',
  postCode: 'SS1 5UP',
  addrLn2: '',
  firstName: 'Danny',
  addrLn1: "112 Saint George's",
  thirdOpt: '',
  town: 'Leigh-on-sea',
};

// Define the Arrows from React Day Picker v7 to keep UI consistent with previous iteration
export const PreviousArrowImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAVVJREFUWAnN2G0KgjAYwPHpGfRkaZeqvgQaK+hY3SUHrk1YzNLay/OiEFp92I+/Mp2F2Mh2lLISWnflFjzH263RQjzMZ19wgs73ez0o1WmtW+dgA01VxrE3p6l2GLsnBy1VYQOtVSEH/atCCgqpQgKKqYIOiq2CBkqtggLKqQIKgqgCBjpJ2Y5CdJ+zrT9A7HHSTA1dxUdHgzCqJIEwq0SDsKsEg6iqBIEoq/wEcVRZBXFV+QJxV5mBtlDFB5VjYTaGZ2sf4R9PM7U9ZU+lLuaetPP/5Die3ToO1+u+MKtHs06qODB2zBnI/jBd4MPQm1VkY79Tb18gB+C62FdBFsZR6yeIo1YQiLJWMIiqVjQIu1YSCLNWFgijVjYIuhYYCKoWKAiiFgoopxYaKLUWOii2FgkophYp6F3r42W5A9s9OcgNvva8xQaysKXlFytoqdYmQH6tF3toSUo0INq9AAAAAElFTkSuQmCC"
export const NextArrowImg = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAwCAYAAAB5R9gVAAAABGdBTUEAALGPC/xhBQAAAXRJREFUWAnN119ugjAcwPHWzJ1gnmxzB/BBE0n24m4xfNkTaOL7wOtsl3AXMMb+Vjaa1BG00N8fSEibPpAP3xAKKs2yjzTPH9RAjhEo9WzPr/Vm8zgE0+gXATAxxuxtqeJ9t5tIwv5AtQAApsfT6TPdbp+kUBcgVwvO51KqVhMkXKsVJFXrOkigVhCIs1Y4iKlWZxB1rX4gwlpRIIpa8SDkWmggrFq4IIRaJKCYWnSgnrXIQV1r8YD+1Vrn+bReagysIFfLABRt31v8oBu1xEBttfRbltmfjgEcWh9snUS2kNdBK6WN1vrOWxObWsz+fjxevsxmB1GQDfINWiev83nhaoiB/CoOU438oPrhXS0WpQ9xc1ZQWxWHqUYe0I0qrKCQKjygDlXIQV2r0IF6ViEBxVTBBSFUQQNhVYkHIVeJAtkNsbQ7c1LtzP6FsObhb2rCKv7NBIGoq4SDmKoEgTirXAcJVGkFSVVpgoSrXICGUMUH/QBZNSUy5XWUhwAAAABJRU5ErkJggg=="

export const DEFAULT_COUNTRY_OBJECT = { country: countries.CNUK.id }