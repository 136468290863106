import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  selectChannel,
  selectDate,
  selectDuration,
  selectGuestsAdults,
  selectGuestsChildren,
  selectGuestsUnder5s,
  selectMonth,
  selectPetFriendly,
  selectRegion,
} from '../../redux/search/form/actions';

import { DDMMYYYYToDate } from '../../utils/date';
import { ynToBool } from '../../utils/utils';
import { dateValidator } from '../../utils/validation';
import {
  searchFormAdultsSelector,
  searchFormArrivalDateSelector,
  searchFormChannelIdSelector,
  searchFormChildrenSelector,
  searchFormDurationSelector,
  searchFormMonthSelector,
  searchFormPetFriendlySelector,
  searchFormRegionIdSelector,
  searchFormUnder5sSelector,
} from '../../redux/search/form/selectors';

export const WithUrlParams = (props) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [readyToDisplay, setReadyToDisplay] = useState(!search);
  const channelId = useSelector(searchFormChannelIdSelector);
  const regionId = useSelector(searchFormRegionIdSelector);
  const arrivalDate = useSelector(searchFormArrivalDateSelector);
  const duration = useSelector(searchFormDurationSelector);
  const _month = useSelector(searchFormMonthSelector);
  const adults = useSelector(searchFormAdultsSelector);
  const children = useSelector(searchFormChildrenSelector);
  const under5s = useSelector(searchFormUnder5sSelector);
  const petFriendly = useSelector(searchFormPetFriendlySelector);

  const updateQueryParamsIfNecessary = () => {
    const query = new URLSearchParams(search);
    // Holiday search
    if (query.has('channel') && query.get('channel') !== channelId) {
      selectChannel(dispatch, query.get('channel'));
    }
    if (query.has('region') && query.get('region') !== regionId) {
      selectRegion(dispatch, query.get('region'));
    }
    if (query.has('nights') && query.get('nights') !== duration) {
      selectDuration(dispatch, parseInt(query.get('nights')));
    }
    if (query.has('date') && dateValidator(query.get('date'))) {
      const [, month, year] = query.get('date').split('/');
      const newDate = `${year}-${month}`;
      if (newDate !== _month) {
        selectMonth(dispatch, newDate);
      }
      const newArrivalDate = DDMMYYYYToDate(query.get('date'));
      const newDateFormatted = new Date(newArrivalDate)?.toISOString();
      const currentDateFormatted = arrivalDate ? new Date(arrivalDate)?.toISOString() : null;

      if (newDateFormatted !== currentDateFormatted) {
        selectDate(dispatch, newArrivalDate);
      }
    }
    if (query.has('adults') && query.get('adults') !== adults) {
      const adults = parseInt(query.get('adults'));
      if (!isNaN(adults)) selectGuestsAdults(dispatch, adults);
    }
    if (query.has('children') && query.get('children') !== children) {
      const children = parseInt(query.get('children'));
      if (!isNaN(children)) selectGuestsChildren(dispatch, children);
    }
    if (query.has('infants') && query.get('infants') !== under5s) {
      const infants = parseInt(query.get('infants'));
      if (!isNaN(infants)) selectGuestsUnder5s(dispatch, infants);
    }
    if (query.has('dogs') && ynToBool(query.get('dogs').toLowerCase()) !== petFriendly) {
      const dogs = ynToBool(query.get('dogs').toLowerCase());
      if (typeof dogs === 'boolean') selectPetFriendly(dispatch, dogs);
    }
  };

  useEffect(() => {
    if (!search) {
      return;
    }

    setReadyToDisplay(false);
    updateQueryParamsIfNecessary();
    setReadyToDisplay(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, search]);

  if (!readyToDisplay) {
    return null;
  }

  return props.children;
};

export default WithUrlParams;
